export interface MathJaxConfig {
    chtml?,
    options?,
    [key: string]: any;
}

declare let FilesFingerprint: any;

export default class MathJax {
    public src: string;


    get config(): MathJaxConfig {
        return {
            chtml: {
                mathmlSpacing: true,
            }
        }
    }


    elements = {
        head: document.head,
        mathJaxSrc: document.createElement('script'),
        mathJaxConfig: document.createElement('script')
    };

    constructor() {
        this.src = `/specs/products/marlin/ha/releasedAssets/public/mathjax-${FilesFingerprint.mathjax}/es5/tex-mml-chtml.js`;
        try {
            if ((window as any).opera) {
                this.elements.mathJaxConfig.innerHTML = `window.MathJax = ${JSON.stringify(this.config)}`;
            } else {
                this.elements.mathJaxConfig.text = `window.MathJax = ${JSON.stringify(this.config)}`;
            }
            this.elements.head.appendChild(this.elements.mathJaxConfig);
            this.elements.mathJaxSrc.type = 'text/javascript';
            this.elements.mathJaxSrc.async = true;
            this.elements.mathJaxSrc.src = this.src;
            this.elements.head.appendChild(this.elements.mathJaxSrc);
        } catch (error) {
            console.error("something went wrong when importing MathJax :", error)
        }
    }
}
