(function () {
    const $window = $(window);

    const adplaceholder = {
        time: 7000,
        timer: null,
        adToHide: null,
        startTimer: null,
        adToHideHeight: null,
        adToHideSelector: '.js__adToHide',
        vPort: 'screen-sm',
        $adPlaceholder: $('.adplaceholder--header'),
        $adPlaceholderTopHegith: 0,
        $adPlaceHolderVertical: $('.body-verticalAd'),
        $adPlaceHolderHorizontal: $('.body-horizontalAd'),
        adPlaceholdersRight: null,
        isAdHidden: false,
        adHiddenByCounter: false,
        space: 20,
        articleTocHeaderHeight:
            $('.article__header').length > 0
                ? $('.article-header').outerHeight(true)
                : $('.toc-header').outerHeight(true),
        isAdVerticalHidden: false,
        isAdHorizontalHidden: false,
        scrolledDown: false,
        isMobile: $(window).width() < UX.grid.screenSm,
        isTablet: $(window).width() < UX.grid.screenMd,
        adPlaceHolderHeight: $('.adplaceholder--header').outerHeight(),
        headerHeight: $('.header').outerHeight(),
        articleToolsHeight: null,
        isHidden: false,
        init: function () {
            window.observeDOM(adplaceholder.$adPlaceholder.get(0), false, false, function () {
                if (!adplaceholder.isAdHidden) {
                    adplaceholder.$adPlaceholder.get(0).style.display = 'flex';
                    if (adplaceholder.$adPlaceholder.get(0).querySelector('.gpt-ad')) {
                        adplaceholder.$adPlaceholder.get(0).style.overflow = 'visible';
                        adplaceholder.$adPlaceholder.get(0).style.minHeight =
                            adplaceholder.$adPlaceholder.height() + 'px';
                    } else {
                        adplaceholder.$adPlaceholder.get(0).style.overflow = 'hidden';
                        adplaceholder.$adPlaceholder.get(0).style.minHeight = 'auto';
                    }
                    adplaceholder.adToHide.removeClass('mobile-hidden');
                    if (adplaceholder.isMobile) {
                        $('.header__wrapper').css('margin-top', $('.adplaceholder--header').outerHeight() + 'px');
                    }
                }
                adplaceholder.setRootProperties();
            });
            if (this.$adPlaceholder.length) {
                window.observeDOM(
                    adplaceholder.$adPlaceholder.get(0).querySelector('.gpt-ad'),
                    true,
                    {attributes: true},
                    function () {
                        adplaceholder.isAdHidden =
                            adplaceholder.$adPlaceholder.find('.gpt-ad').css('display') === 'none';
                        adplaceholder.isHidden = adplaceholder.isAdHidden;
                        adplaceholder.hideAd();
                    }
                );
                adplaceholder.hideAd();
            }
            if (this.$adPlaceHolderVertical.length) {
                window.observeDOM(
                    adplaceholder.$adPlaceHolderVertical.get(0).querySelector('.gpt-ad'),
                    true,
                    {attributes: true},
                    function () {
                        adplaceholder.isAdVerticalHidden =
                            adplaceholder.$adPlaceHolderVertical.find('.gpt-ad').css('display') === 'none';
                        adplaceholder.isHidden = adplaceholder.isAdVerticalHidden;
                        adplaceholder.hideAd();
                    }
                );
                adplaceholder.hideAd();
            }
            if (this.$adPlaceHolderHorizontal.length) {
                window.observeDOM(
                    adplaceholder.$adPlaceHolderHorizontal.get(0).querySelector('.gpt-ad'),
                    true,
                    {attributes: true},
                    function () {
                        adplaceholder.isAdHorizontalHidden =
                            adplaceholder.$adPlaceHolderHorizontal.find('.gpt-ad').css('display') === 'none';
                        adplaceholder.isHidden = adplaceholder.isAdHorizontalHidden;
                        adplaceholder.hideAd();
                    }
                );
                adplaceholder.hideAd();
            }
            this.adToHide = $(this.adToHideSelector);
            $(document).on(adplaceholder.vPort + '-on', function () {
                adplaceholder.isMobile = true;
            });
            $(document).on(adplaceholder.vPort + '-off', function () {
                adplaceholder.isMobile = false;
            });

            this.setRootProperties();

            if (this.isMobile && !this.isAdHidden) {
                this.time = 4000;
                $('.header__wrapper').css('margin-top', $('.adplaceholder--header').outerHeight() + 'px');
                if (this.$adPlaceholder.closest('.pb-normal-view').length === 0) {
                    this.adToHide.css({
                        position: 'fixed',
                        zIndex: 10000,
                    });
                }
                this.adToHide.css({
                    width: '100%',
                });
            }
            if (!adplaceholder.isAdHidden) {
                $window.on('scroll', this.on.scroll);
            }

            if ($('.js__toggleAdForm').length > 0) {
                $('.js__toggleAdForm').on('click', function (e) {
                    e.preventDefault();
                    let elemToToggle = '#' + $(this).data('toggle');
                    $(elemToToggle).slideToggle();
                });
            }
            this.adPlaceholdersRight = document.querySelector('.adplaceholders:not(.not-sticky)');
            this.articleToolsHeight = this.on.get.articleToolsHeight();
        },
        hideAd: function () {
            if (adplaceholder.$adPlaceholder && adplaceholder.isAdHidden) {
                adplaceholder.$adPlaceholder.css('display', 'none');
                adplaceholder.on.hide();
                if (adplaceholder.isMobile) {
                    $('.header__wrapper').removeAttr('style');
                }
            }
            if (adplaceholder.$adPlaceHolderVertical && adplaceholder.isAdVerticalHidden) {
                adplaceholder.$adPlaceHolderVertical.css('display', 'none');
            }
            if (adplaceholder.$adPlaceHolderHorizontal && adplaceholder.isAdHorizontalHidden) {
                adplaceholder.$adPlaceHolderHorizontal.css('display', 'none');
            }
            UX.pageBody.init();
            adplaceholder.setRootProperties();
        },
        setTimer: function () {
            if (adplaceholder.startTimer) {
                adplaceholder.clearTimer();
                adplaceholder.timer = setTimeout(adplaceholder.on.hide, adplaceholder.time);
            }
        },
        clearTimer: function () {
            clearTimeout(adplaceholder.timer);
            adplaceholder.timer = null;
        },
        on: {
            hide: function () {
                if (adplaceholder.adToHide.css('display') !== 'none') {
                    adplaceholder.adToHide.hide();
                    adplaceholder.adToHide.addClass('mobile-hidden');
                    if (UX.drawer && UX.drawer.update) {
                        UX.drawer.update.top($('.menu-drawer__ctrl'));
                    }
                    if (UX.LSN && UX.LSN.on) {
                        setTimeout(function () {
                            UX.LSN.on.scroll();
                        }, 0);
                    }
                    if (adplaceholder.adPlaceholdersRight) {
                        adplaceholder.on.scroll();
                    }
                }
                adplaceholder.adHiddenByCounter = true;
                adplaceholder.setRootProperties();
            },
            get: {
                articleToolsHeight: function () {
                    let $el = $('.article-tools__wrapper').clone();
                    let height;
                    if ($el.length > 0) {
                        $el.css('position', 'absolute');
                        $el.css('left', '-99999px');
                        $el.css('padding', '13px');
                        $('.article-tools').append($el);
                        height = $el.outerHeight();
                        $el.remove();
                    } else {
                        height = 0;
                    }

                    return height;
                },
            },
            scroll: function (e) {
                let scrollTop = $(window).scrollTop();
                if (scrollTop > adplaceholder.adPlaceHolderHeight && adplaceholder.isMobile) {
                    adplaceholder.startTimer = true;
                    adplaceholder.setTimer();
                } else if (scrollTop < adplaceholder.adPlaceHolderHeight && adplaceholder.isMobile) {
                    adplaceholder.startTimer = false;
                    adplaceholder.setTimer();
                } else {
                    adplaceholder.startTimer = true;
                    adplaceholder.setTimer();
                }
                if (scrollTop <= 0) {
                    adplaceholder.clearTimer();
                    if (
                        adplaceholder.scrolledDown &&
                        !$('body').hasClass('lock-screen') &&
                        adplaceholder.adHiddenByCounter
                    ) {
                        adplaceholder.adToHide.removeClass('mobile-hidden');
                        if (adplaceholder.isMobile) {
                            adplaceholder.$adPlaceholder.get(0).style.position = '';
                        }
                        adplaceholder.adToHide.css({display: 'flex'});
                        adplaceholder.scrolledDown = false;
                    } else {
                        adplaceholder.scrolledDown = false;
                        if (
                            adplaceholder.$adPlaceholder.find('.gpt-ad').first().css('display') !== 'none' ||
                            adplaceholder.$adPlaceHolderVertical.find('.gpt-ad').first().css('display') !== 'none'
                        ) {
                            adplaceholder.adToHide.css({display: 'flex'});
                        }
                    }
                    if (UX.drawer) {
                        UX.drawer.update.top($('.menu-drawer__ctrl'));
                    }
                    adplaceholder.setRootProperties();
                } else {
                    if (!adplaceholder.scrolledDown) {
                        adplaceholder.setTimer();
                    }
                    adplaceholder.scrolledDown = true;
                }
                if (adplaceholder.scrolledDown && adplaceholder.adHiddenByCounter) {
                    adplaceholder.adToHide.hide();
                    if (adplaceholder.isMobile) {
                        $('.header__wrapper').removeAttr('style');
                    }
                    adplaceholder.setRootProperties();
                }
                if ($('.table-of-content').length > 0) {
                    let scrollTop = $(window).scrollTop(),
                        toolsHeight = $('.article-tools.sticky .article-tools__wrapper').length
                            ? $('.article-tools.sticky .article-tools__wrapper').outerHeight()
                            : 0,
                        articleHeaderTop =
                            $('.article-header').length && $('.article-header').position()
                                ? $('.article-header').position().top
                                : 0,
                        tocHeaderTop =
                            $('.toc-header').length && $('.toc-header').position()
                                ? $('.toc-header').position().top
                                : 0,
                        articleHeaderHeight = $('.article-header').length ? $('.article-header').outerHeight(true) : 0,
                        tocHeaderHeight = $('.toc-header').length ? $('.toc-header').outerHeight(true) : 0,
                        stickypoint =
                            articleHeaderTop +
                            tocHeaderTop +
                            articleHeaderHeight +
                            tocHeaderHeight +
                            parseInt($('.table-of-content').css('marginTop')) -
                            adplaceholder.headerHeight -
                            toolsHeight -
                            20,
                        footerTop = $('.footer').length === 1 ? $('.footer').offset().top : $(document).height(),
                        windowHeight = $(window).height(),
                        windowBottom = scrollTop + windowHeight;
                    footerTop = footerTop - adplaceholder.adPlaceHolderHeight;
                    if ($('.adplaceholder--header').css('display') === 'none') {
                        adplaceholder.$adPlaceholderTopHegith = 0;
                        stickypoint = stickypoint + adplaceholder.adPlaceHolderHeight;
                        footerTop = footerTop + (adplaceholder.adPlaceHolderHeight - 30);
                    } else {
                        adplaceholder.$adPlaceholderTopHegith = adplaceholder.adPlaceHolderHeight;
                    }

                    adplaceholder.toggleSticky(scrollTop, stickypoint, windowBottom, footerTop);
                }
                UX.pageBody.on.setContentTopPadding();
            },
        },
        toggleSticky: function (scrollTop, stickypoint, windowBottom, footerTop) {
            if (scrollTop > stickypoint) {
                if (adplaceholder.adPlaceholdersRight) {
                    adplaceholder.adPlaceholdersRight.classList.add('js--sticky');
                    if (windowBottom < footerTop) {
                        adplaceholder.adPlaceholdersRight.style.position = 'fixed';
                        adplaceholder.adPlaceholdersRight.style.top =
                            $('.header').height() - adplaceholder.articleToolsHeight + 'px';
                        adplaceholder.adPlaceholdersRight.style.bottom = 'auto';
                    } else {
                        adplaceholder.adPlaceholdersRight.style.position = 'absolute';
                        adplaceholder.adPlaceholdersRight.style.top = 'auto';
                        adplaceholder.adPlaceholdersRight.style.bottom = '10px';
                    }
                }
            } else {
                if (adplaceholder.adPlaceholdersRight) {
                    adplaceholder.adPlaceholdersRight.classList.remove('js--sticky');
                    adplaceholder.adPlaceholdersRight.style.position = 'absolute';
                    adplaceholder.adPlaceholdersRight.style.top = 0;
                    adplaceholder.adPlaceholdersRight.style.bottom = 'auto';
                }
            }
        },
        setRootProperties: function () {
            const wrapperHeight = $('.header__wrapper').outerHeight();
            const height = $('.header').outerHeight();
            const brandingRow = $('#brandingRow');
            let brandingRowHeight = 0;
            if (brandingRow.length > 0 && brandingRow.css('display') !== 'none') {
                brandingRowHeight = brandingRow.outerHeight();
            }
            const value = height ? height - brandingRowHeight : null;
            document
                .querySelector(':root')
                ?.style.setProperty(
                    '--article-sticky-top',
                    value ? (value > wrapperHeight ? value : wrapperHeight) + 'px' : null
                );
            document
                .querySelector(':root')
                ?.style.setProperty(
                    '--scroll-padding',
                    height
                        ? (height > wrapperHeight ? height + 62 : wrapperHeight) + 'px auto auto'
                        : '8.875rem auto auto'
                );
        },
    };

    UX.adplaceholder = adplaceholder; // add to global namespace
})();
