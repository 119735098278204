interface Categories {
    name: string;
    total: number;
}

interface PlumXData {
    id_value: string;
    count_categories: Categories[];
}

interface Items {
    plumX: string;
    content: string;
}

interface Data {
    articles?: Items[];
    items?: Items[];
}

interface JsonData {
    data: Data;
}

export default class PlumX {
    constructor() {
        this.init();
    }

    flags: {
        hidden: string;
        publicationList: string;
        multiSearch: string;
    } = {
        hidden: 'hidden',
        publicationList: 'publicationList',
        multiSearch: 'multiSearch'
    };

    classList: Record<string, string> = {
        hidden: 'js--hidden',
        loaded: 'js--loaded',
        loading: 'js--loading',
        plumXAxel: 'plum-x--axel',
        plumXListBox: 'plum-x-list-box',
        publicationListAxel: 'publication-list--axel',
        axelListBox: 'axel-list__box',
        axelPublicationListBox: 'publication-list__box-axel'
    };

    selectors: Record<string, string> = {
        plumXLazyLoad: '.plum-x--lazy-load',
        plumXSpinner: '.plum-x--spinner',
        publicationListArticlePreview: '.publication-list__article__preview',
        previewLink: '.preview_link',
        axelSection: '.publication-list__axel-section',
        axelPublicationList: '.publication-list--axel'
    };

    elements: {
        section: HTMLElement | null;
        plumXLazyLoad: NodeListOf<HTMLElement> | null;
        axelSection: HTMLElement | null;
        axelPublicationList: HTMLElement | null;
    } = {
        section: null,
        plumXLazyLoad: null,
        axelSection: null,
        axelPublicationList: null
    };

    setElements() {
        this.elements.plumXLazyLoad = document.querySelectorAll(this.selectors.plumXLazyLoad);
        this.elements.axelSection = document.querySelector(this.selectors.axelSection);
        this.elements.axelPublicationList = document.querySelector(this.selectors.axelPublicationList);
    }

    isAxel = (item: HTMLElement): boolean => item.classList.contains(this.classList.publicationListAxel);

    PlumXItems = (categoryList: string[], data: Categories[], widgetCaptions: Record<string, string>) => {
        let plumXData: string = '';
        categoryList.map(item => {
            const dataObj = data.find(obj => obj.name === item);
            if (dataObj) {
                const number: string = dataObj.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                let content: string = `
                    <div class="plum-x__item plum-x-${dataObj.name}">
                        <div class="plum-x__item-num">${number}</div>
                        <div class="plum-x__item-title">${widgetCaptions[dataObj.name]}</div>
                    </div>    
                `;
                plumXData += content;
            }
        });
        return plumXData;
    };

    PlumXRender = (item: HTMLElement, data: Categories[], doiUrl: string) => {
        const categoryList: string[] = ['citation', 'capture', 'mention', 'socialMedia'];
        const widgetCaptions: Record<string, string> = {
            citation: 'Citations',
            capture: 'Captures',
            mention: 'Mentions',
            socialMedia: 'Social Media'
        };

        let dataNum: number = 0;

        data.map(({ name }) => {
            const hasCategory: string = categoryList.find(c => c === name);
            if (hasCategory) dataNum++;
        });

        if (!dataNum) return '';

        return `
            <li class="plum-x-wrapper">
                <div class="plum-x${this.isAxel(item) ? ` ${this.classList.plumXAxel}` : ''}">
                    <div class="plum-x__items">
                        ${this.PlumXItems(categoryList, data, widgetCaptions)}
                    </div>
                    <div class="plum-x__details">
                        <div class="plum-x__logo">
                            <img src="/products/marlin/releasedAssets/images/plumx-logo.png" alt="Plum X logo">
                        </div>
                        <a class="plum-x__view-details" href="https://plu.mx/plum/a/?doi=${doiUrl}&theme=plum-jbs-theme&hideUsage=true" target="_blank">
                            <span>View details</span>
                            <i class="${this.isAxel(item) ? 'icon-external-arrow' : 'icon-arrow_r'}" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>  
            </li>
        `;
    };

    publicationListBoxRender = (item: HTMLElement, articleData: Items[], widgetName: string) => {
        let content: string = '';
        let plumXCounter: number = 0;
        articleData.map(list => {
            let plumXContent: string = '';
            if (list.plumX) {
                const plumXList = JSON.parse(list.plumX) as PlumXData;
                let doiUrl: string = plumXList.id_value ? plumXList.id_value : '';

                if (plumXList.count_categories) {
                    plumXContent = this.PlumXRender(item, plumXList.count_categories, doiUrl);
                    plumXCounter++;
                }
            }
            if (this.isAxel(item) && !plumXCounter) {
                item.remove();
                this.removeWidgetHeader(item);
            } else {
                const html = `
                <li class="publication-list__box${widgetName === this.flags.multiSearch ? ` ${this.classList.plumXListBox}` : ''}${this.isAxel(item) ? ` ${this.classList.axelPublicationListBox}` : ''}">
                    <ul class="rlist${this.isAxel(item) ? ` ${this.classList.axelListBox}` : ''}">
                        ${!this.isAxel(item) ? list.content : ''}
                        ${plumXContent}
                    </ul>
                </li>`
                ;
                content += html;
            }
        });

        item.insertAdjacentHTML('afterbegin', content);

        const publicationListArticlePreview = item.querySelectorAll(this.selectors.publicationListArticlePreview);
        // @ts-ignore
        if (publicationListArticlePreview.length) UX.accordion.init();
        const previewLink = item.querySelectorAll(this.selectors.previewLink);
        // @ts-ignore
        previewLink.forEach(item => item.addEventListener('click', UX.mostCitedArticles.previewLinkClickHandler));
    };

    addNoResultMessage = (item: HTMLElement, textForNoResults: string) => {
        const content = `<div class="publication-list__no-results">${textForNoResults}</div>`;
        item.insertAdjacentHTML('beforebegin', content);
    };

    removeWidgetHeader = (item: HTMLElement) => {
        if (this.isAxel(item)) {
            this.elements.axelSection?.insertAdjacentHTML('beforeend', '<span>Metric data currently unavailable</span>');
        } else {
            const widgetHeader = item.previousElementSibling;
            if (widgetHeader && widgetHeader.tagName === 'H2') widgetHeader.remove();
        }
    };

    renderNoResults = (item: HTMLElement) => {
        const { zeroResultsDisplayOptions, textForNoResults } = item.dataset;
        if (zeroResultsDisplayOptions && textForNoResults) {
            this.addNoResultMessage(item, textForNoResults);
        } else {
            this.removeWidgetHeader(item);
        }
        item.remove();
    };

    fetchPlumXData = async (item: HTMLElement): Promise<void> => {
        const { plumXUrl } = item.dataset;
        const spinner = item.querySelector(this.selectors.plumXSpinner) as HTMLElement;
        if (plumXUrl && !item.classList.contains(this.classList.loaded) && !item.classList.contains(this.classList.loading)) {
            spinner.classList.remove(this.flags.hidden);
            item.classList.add(this.classList.loading);
            const response = await fetch(plumXUrl);
            const jsonData: JsonData = await response.json();
            const { data } = jsonData as JsonData;
            let articleData = [];
            let widgetName: string = '';

            if (data.articles) {
                articleData = [...data.articles];
                widgetName = this.flags.publicationList;
            } else if (data.items) {
                articleData = [...data.items];
                widgetName = this.flags.multiSearch;
            }

            if (articleData && articleData.length > 0) {
                this.publicationListBoxRender(item, articleData, widgetName);
            } else {
                this.renderNoResults(item);
            }
            item.classList.remove(this.classList.loading);
            item.classList.add(this.classList.loaded);
            spinner.remove();
        }
    };

    contentRender: IntersectionObserverCallback = (entries, observer) => {
        entries.forEach(async entry => {
            if (entry.intersectionRatio > 0) {
                const item = entry.target as HTMLElement;
                await this.fetchPlumXData(item);
                observer.unobserve(entry.target);
            }
        });
    };

    observerInit = () => {
        let config = {
            root: null,
            rootMargin: '0px',
            threshold: 0
        };

        let observer = new IntersectionObserver(this.contentRender, config);
        this.elements.plumXLazyLoad.forEach(item => {
            observer.observe(item);
        });
    };

    isPbMode = (): boolean => this.elements.axelPublicationList.dataset.pbModeEnabled === 'true';

    onReady = () => {
        !this.isPbMode() && this.observerInit();
    };

    init() {
        const plumXLazyLoad = document.querySelector('.plum-x--lazy-load') as HTMLElement;
        if (!plumXLazyLoad) return;
        this.elements.section = plumXLazyLoad;
        this.setElements();
        this.onReady();
    }
}